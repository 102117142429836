<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="requestChangePassword"
  >
    <img src="/img/icon.png" />
    <h2 class="title">Recuperación de cuenta</h2>
    <div class="one">
      <v-text-field
        type="text"
        class="input"
        placeholder="Correo"
        prepend-inner-icon="mdi-account"
        v-model="username"
        :rules="userRules"
        solo
      >
      </v-text-field>
    </div>
    <v-row align="center" justify="space-around">
      <v-btn
        depressed
        class="btn-confirm"
        @click="requestChangePassword"
        :disabled="loading"
      >
        <v-progress-circular
          color="white"
          indeterminate
          v-if="loading"
        ></v-progress-circular>
        <div v-else>Recuperar</div>
      </v-btn>
      <v-btn depressed @click="changeComponent" class="btn-cancel">
        Cancelar
      </v-btn>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">Confirmar código</v-card-title>
          <v-card-text>
            <v-text-field
              type="text"
              class="input"
              placeholder="Código"
              prepend-inner-icon="mdi-shield-lock"
              v-model="code"
              :rules="codeRules"
            >
            </v-text-field>
            <v-text-field
              type="password"
              class="input"
              placeholder="Contraseña"
              prepend-inner-icon="mdi-lock"
              v-model="password"
              :rules="passwordRules"
            >
            </v-text-field>
            <v-text-field
              type="password"
              class="input"
              placeholder="Confirmar contraseña"
              prepend-inner-icon="mdi-lock"
              v-model="confirmPassword"
              :rules="passwordRules"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default darken-1" text @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn color="green darken-1" text @click="confirmAccount">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
// import { RequestChangePassword, ConfirmChangePassword } from "../../app/auth";

export default {
  name: "RecoverAccount",
  data() {
    return {
      code: "",
      dialog: false,
      username: "",
      password: "",
      confirmPassword: "",
      userRules: [
        (value) => !!value || "Ingrese correo",
        (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "Ingrese un correo valido",
      ],
      passwordRules: [
        (value) => !!value || "Ingrese contraseña",
        (value) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).+$/.test(
            value
          ) ||
          "La contraseña debe contener al menos una letra mayúscula, una minúscula, un número y un caracter especial",
        (value) =>
          value.length >= 8 ||
          "La contraseña debe contener al menos 8 caracteres",
      ],
      codeRules: [(value) => !!value || "Ingrese código"],
      loading: false,
      valid: false,
    };
  },
  methods: {
    changeComponent() {
      this.$router.push({ name: "Auth" });
    },
    async confirmAccount() {
      if (!this.$refs.form.validate()) return;
      this.dialog = true;
    },
    async requestChangePassword() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.loading = false;
    },
  },
};
</script>

<style scoped src="@/assets/css/login.css"></style>
